import React, { useState, useEffect, useCallback } from 'react';
import {
	SypacPagination,
	SypacInput,
	SypacText,
	SypacIcon,
} from '@sypac/component-library-react';
import { MutatingDots } from 'react-loader-spinner';
import { T, useTranslate } from '@tolgee/react';
import { LeftSideMenu } from '../../../components/Users/LeftSideMenu/LeftSideMenu';
import { CompaniesTable } from '../../../components/CompaniesTable/CompaniesTable';
import useDebounce from '../../../hooks/useDebounce';
import './dashboard.css';
import {
	CompanyService,
	CompanyShort,
} from '../../../services/company.services';
import CompanyDetails from '../ComanyDetails';
import { useGetCompanyAdminStats } from '../../../hooks/use-get-stats';
import { camelToSnakeCase } from '../../../utils/camle-to-snake-case.util';
import CompanyVerification from '../../../components/CompanyVerification';

const Dashboard: React.FC = () => {
	const { t } = useTranslate();
	const [count, setCount] = useState<number>(0);
	const [companies, setCompanies] = useState<CompanyShort[]>([]);
	const [status, setStatus] = useState<string>('all');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [refresh, setRefresh] = useState<number>(0);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [page, setPage] = useState<number>(1);
	const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
		undefined,
	);
	const [showVerification, setShowVerification] = useState<boolean>(false);
	const [showDetails, setShowDetails] = useState<boolean>(false);

	const [stats] = useGetCompanyAdminStats(refresh);

	const useOnChange = (e: any) => {
		setPage(1);
		return useDebounce(setSearchQuery(e.currentTarget.value));
	};

	const getUserCallback = useCallback(async () => {
		setIsLoading(true);
		try {
			const query = {
				status: camelToSnakeCase(status),
				search: searchQuery,
				limit: 10,
				offset: (page - 1) * 10,
			};
			const { data } = await CompanyService.getCompanies(query);
			setCount(data.count);
			setCompanies(data.items);
		} finally {
			setIsLoading(false);
		}
	}, [page, searchQuery, status]);

	useEffect(() => {
		getUserCallback();
	}, [getUserCallback]);

	const openCompany = (company: CompanyShort) => {
		setSelectedCompany(company.id);
		setShowDetails(true);
	};

	const completeVerification = () => {
		setShowDetails(true);
		setShowVerification(false);
		setRefresh(refresh + 1);
		getUserCallback();
	};

	const setTab = (status: string) => {
		setStatus(status);
		setPage(1);
		setRefresh(refresh + 1);
	};

	return (
		<div className="flex gap-5 w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-94px)] h-full relative">
			<div className="flex flex-col gap-2.5 w-[500px] ml-5">
				<div className="border border-solid relative border-gray-40 rounded-xl py-3 px-5 flex items-center">
					<div className="flex w-full">
						<button
							className="flex items-center border-0 p-0 bg-transparent cursor-pointer"
							onClick={() => {
								setTimeout(() => {
									getUserCallback();
								}, 400);
							}}
						>
							<SypacIcon
								icon-name="Magnifer"
								className="text-gray-40"
								size="custom"
								width="20px"
								height="20px"
							/>
						</button>
						<input
							className="flex items-center text-base placeholder:text-gray-40 py-0 px-2.5 w-full outline-none border-0 bg-transparent"
							placeholder={t('dashboard.search', 'Search')}
							type="text"
							value={searchQuery}
							onChange={useOnChange}
						/>
					</div>
					<button
						className="flex items-center border-0 p-0 bg-transparent cursor-pointer"
						onClick={() => {
							setIsOpen(!isOpen);
						}}
					>
						<SypacIcon
							icon-name="Tuning 2"
							className="text-gray-40"
							size="custom"
							width="22px"
							height="22px"
						/>
					</button>
					<div
						className={`${
							isOpen ? 'flex' : 'hidden'
						} absolute left-0 top-full mt-3 bg-white rounded-xl p-5 border border-gray-10 flex-wrap gap-4`}
					>
						<SypacInput className="w-full" size="md">
							<label>
								<T keyName="dashboard.country">Country</T>
							</label>
							<input
								className="w-full outline-none border border-gray-10 px-5 py-1.5"
								placeholder={t('dashboard.country', 'Country')}
							/>
						</SypacInput>
						<div className="flex gap-x-3">
							<SypacInput size="md">
								<label>
									<T keyName="dashboard.startRegistrationDate">
										Start registration date
									</T>
								</label>
								<input
									className="w-full outline-none border border-gray-10 px-5 py-1.5"
									placeholder={t('dashboard.startRegDate', 'Start reg. date')}
								/>
							</SypacInput>
							<SypacInput size="md">
								<label>
									<T keyName="dashboard.endRegistrationDate">
										End registration date
									</T>
								</label>
								<input
									className="w-full outline-none border border-gray-10 px-5 py-1.5"
									placeholder={t('dashboard.endRegDate', 'End reg. date')}
								/>
							</SypacInput>
						</div>
						<SypacInput className="w-full" size="md">
							<label>
								<T keyName="dashboard.user">User</T>
							</label>
							<input
								className="w-full outline-none border border-gray-10 px-5 py-1.5"
								placeholder={t('dashboard.selectUser', 'Select user')}
							/>
						</SypacInput>
					</div>
				</div>
				<div className="border border-solid border-gray-10 rounded-xl h-full">
					<LeftSideMenu
						stats={[
							{ value: stats?.all || '0', status: 'all', label: 'All' },
							{
								value: stats?.registrationInProgress || '0',
								status: 'registrationInProgress',
								label: 'Registration (in progress)',
							},
							{
								value: stats?.verificationPending || '0',
								status: 'verificationPending',
								label: 'Verification (pending)',
							},
							{
								value: stats?.verified || '0',
								status: 'verified',
								label: 'Verified',
							},
						]}
						setStatus={(status) => setTab(status)}
						selectedStatus={status}
					/>
				</div>
			</div>
			<div className="flex flex-col border border-solid border-gray-10 rounded-xl h-full w-[calc(100vw-350px)] mr-7.5">
				<div className="border-bottom pl-5 py-1.5">
					<SypacText variant="body-regular-medium">
						<p>{t('admin_dash.registration', 'Registration')}</p>
					</SypacText>
				</div>
				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					<div className="p-5 h-[calc(100vh-200px)] overflow-y-auto">
						<CompaniesTable
							rows={companies}
							onClick={openCompany}
							selectedCompany={selectedCompany}
						/>
					</div>
				)}
				<div className="w-full flex justify-center py-4 border-t border-gray-10">
					<SypacPagination
						count={Math.ceil(count / 10)}
						page={page}
						view-page-range="6"
						onPageChanged={(e) => {
							setPage(e.detail.page);
						}}
					/>
				</div>
				{selectedCompany !== undefined && showDetails ? (
					<CompanyDetails
						onClose={() => {
							setShowDetails(false);
							setSelectedCompany(undefined);
						}}
						companyId={selectedCompany}
						onVerification={() => {
							setShowVerification(true);
							setShowDetails(false);
						}}
					/>
				) : null}
				{selectedCompany !== undefined && showVerification ? (
					<CompanyVerification
						isOpen={showVerification}
						closeModal={completeVerification}
						companyId={selectedCompany}
					/>
				) : null}
			</div>
		</div>
	);
};

export default Dashboard;
