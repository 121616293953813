import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './side-bar-menu.css';
import { LogoShort } from '../../assets/LogoShort';
import { SideBarMenuItem } from '../SideBarMenuItem/SideBarMenuItem';
import { SypacAvatar } from '@sypac/component-library-react';
import { SideBarMenuProps } from './SideBarMenuProps';
import { ProfileModal } from '../ProfileModal/ProfileModal';
import Emitter, { EventType } from '../../services/events';
import { AuthContext } from '../../context/context';
import { NotificationsService } from '../../services/notifications.services';
import useDebounce from '../../hooks/useDebounce';
import { LineStroke } from '../../assets/LineStroke';
import { MenuIcon } from '../../assets/MenuIcon';
import { LogoBlack } from '../../assets/LogoBlack';

export const SideBarMenu = ({ groups }: SideBarMenuProps) => {
	const location = useLocation();
	const [count, setCount] = useState<number>(0);
	const [openedProfile, openProfile] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { user } = useContext(AuthContext);
	const showCount = useDebounce(count, 500);

	const getCount = useCallback(async () => {
		try {
			if (user?.uid) {
				const resp = await NotificationsService.getCount({
					recipientId: user?.uid,
					limit: 50,
				});
				const counter = new Set(resp.data.items.map((item) => item.groupId))
					.size;
				if (counter) {
					// const notificationIds = resp.data?.items
					// 	?.filter((r) => !r.readAt)
					// 	?.map((r) => r.id);
					// await NotificationsService.readNotify(notificationIds);
					setCount(counter);
					Emitter.emit(EventType.ORDER_REFRESH, resp.data.items);
				}
			}
		} catch (e) {}
	}, [user?.uid]);

	useEffect(() => {
		Emitter.on(EventType.NOTIFICATION_COUNT, () => {
			getCount();
		});
		Emitter.on(EventType.NOTIFICATION_COUNT_DECREASE, (count) => {
			setCount((prev) => (prev - count >= 0 ? prev - count : 0));
		});

		return () => {
			Emitter.off(EventType.NOTIFICATION_COUNT);
			Emitter.off(EventType.NOTIFICATION_COUNT_DECREASE);
		};
	}, [getCount]);

	const closeModal = () => {
		openProfile(false);
	};

	useEffect(() => {
		getCount();
	}, [getCount]);

	return (
		<>
			<div className="side-bar">
				<div className="side-bar-group">
					<div className="flex items-center sm:flex-col gap-4 sm:max-w-[42px]">
						<div className="flex flex-col items-center">
							<span className="hidden sm:block scale-[0.8] xl-2xl:scale-100">
								<LogoShort />
							</span>
							<span className="block mt-[4px] sm:hidden">
								<LogoBlack width="97" height="18" />
							</span>
							<span className="hidden sm:block">
								<LineStroke width={[42, 42, 42]} />
							</span>
						</div>

						<div className="side-bar-items">
							{groups.map((item, i) => {
								return (
									<SideBarMenuItem
										key={i}
										url={item.url}
										logo={item.logo}
										count={item.count && showCount ? showCount : 0}
										alt={item.alt}
										active={location.pathname === item.url}
									/>
								);
							})}
						</div>
					</div>
					<div className="hidden sm:block relative">
						<div
							className="flex flex-col gap-4 items-center"
							onMouseOver={() => openProfile(true)}
							onMouseLeave={() => openProfile(false)}
						>
							{openedProfile ? (
								<div className="absolute left-0 bottom-8 z-10">
									<ProfileModal
										isModal={false}
										isLogoutOnly={false}
										isOpen={openedProfile}
										onClose={closeModal}
									/>
								</div>
							) : null}
							<span className="hidden sm:block">
								<LineStroke width={[42, 42, 42]} />
							</span>
							<SypacAvatar
								initials="A"
								onClick={() => {
									openProfile(true);
								}}
								className="scale-75 xl-2xl:scale-100"
								isChosen={openedProfile}
							/>
						</div>
					</div>

					<button
						onClick={() => setIsOpen(!isOpen)}
						className="w-[87px] h-[42px] flex sm:hidden bg-transparent rounded-[10px] border border-solid border-gray-10 justify-center items-center cursor-pointer"
					>
						{isOpen ? (
							<span className="mt-[3px] mr-[5px]">
								<MenuIcon />
							</span>
						) : (
							<span className="mt-[3px] mr-[5px]">
								<MenuIcon />
							</span>
						)}

						{isOpen ? (
							<span className="text-sm text-gray-80">Back</span>
						) : (
							<span className="text-sm text-gray-80">Menu</span>
						)}
					</button>
				</div>
			</div>
			{isOpen && (
				<div className="w-screen h-screen fixed mt-[66px] z-[22] bg-gray-1/5">
					<div className="flex flex-col m-3 gap-3">
						{groups.map((item, i) => {
							return (
								<a
									key={i}
									href={item.url}
									className={`h-[60px] box-border bg-white border border-solid rounded-xl p-5 cursor-pointer no-underline text-gray-80 ${
										location.pathname === item.url
											? 'border-primary-violet'
											: 'border-gray-10'
									}`}
								>
									{item.alt}
								</a>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};
