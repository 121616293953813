import {
	SypacBreadcrumbs,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Payments from '../../components/Payments/Payments';
import Flag from 'react-world-flags';
import Close from '../../assets/Close';
import { OrderContext } from '../../context/OrderContext/order.context';
import { useNavigate } from 'react-router-dom';
import { T } from '@tolgee/react';
import { MapIcon } from '../../assets/MapIcon';
import ClientStepper from '../../components/ClientStepper/ClientStepper';
import ClientOrderSummary from '../../components/ClientOrderSummary/ClientOrderSummary';
import { GeoService } from '../../services/geo.services';
import { OrdersService, Prices } from '../../services/orders.services';
import { QuantityPickerContext } from '../../context/QuantityPickerContext/quantity-picker.context';

const ProductsPayment = () => {
	const navigate = useNavigate();
	const { order } = useContext(OrderContext);
	const address = useMemo(
		() =>
			order?.location
				? [
						[
							order?.location?.address?.street,
							order?.location?.address?.houseNumber,
						].join(' ') || '',
						order?.location?.address?.postalCode || '',
						order?.location?.address?.city || '',
						order?.location?.address?.country || '',
				  ]
						.filter((r) => r)
						.join(', ')
				: '',
		[order?.location],
	);
	const [quantity, setQuantity] = useState(0);
	const [prices, setPrices] = useState<Prices>({
		productPriceForClient: 0,
		productPriceForClientWithVat: 0,
		transportPriceForClient: 0,
		transportPriceForClientWithVat: 0,
	});

	const currentProduct = useMemo(() => order?.product, [order?.product]);
	const currentCategory = useMemo(() => order?.category, [order?.category]);
	const currentGroup = useMemo(() => order?.group, [order?.group]);

	const { totalQuantityCounter, inputQuantity } = useContext(
		QuantityPickerContext,
	);

	useEffect(() => {
		if (totalQuantityCounter?.length) {
			const result = totalQuantityCounter?.reduce(
				(total, item) => total + item.payload * item.quantity,
				0,
			);
			setQuantity(result);
		} else {
			setQuantity(0);
		}
	}, [totalQuantityCounter]);

	const getPrices = useCallback(async () => {
		try {
			let quantity = 0;
			let trucksNr = 1;
			if (totalQuantityCounter?.length) {
				quantity = totalQuantityCounter?.reduce(
					(total, item) => total + item.payload * item.quantity,
					0,
				);
				trucksNr = totalQuantityCounter?.reduce(
					(total, item) => total + item.quantity,
					0,
				);
			}
			if (inputQuantity) {
				quantity = inputQuantity;
			}
			let latDelivery = 0;
			let longDelivery = 0;
			if (order?.location?.locationId) {
				const resp = await GeoService.getLocationDetails({
					locationid: order?.location?.locationId,
				});
				latDelivery = resp?.data?.Latitude;
				longDelivery = resp?.data?.Longitude;
			}
			const { data } = await OrdersService.calculatePrice({
				quantity,
				trucksNr,
				latPickup: order?.product?.location?.coordinates?.length
					? order?.product?.location?.coordinates[1]
					: 47.04014,
				longPickup: order?.product?.location?.coordinates.length
					? order?.product?.location?.coordinates[0]
					: 28.80058,
				latDelivery,
				longDelivery,
				productId: currentProduct?.id!,
			});
			setPrices(data);
		} catch (e) {}
	}, [
		currentProduct?.id,
		inputQuantity,
		order?.location?.locationId,
		order?.product?.location?.coordinates,
		totalQuantityCounter,
	]);

	useEffect(() => {
		getPrices();
	}, [getPrices]);

	const onSuccess = (id: number) => {
		navigate(`/store/${id}/thank-you`);
	};

	return (
		<div className="w-full flex flex-col gap-4 h-[calc(100vh-60px)] gap-x-2.5 overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0">
			{/*<SearchBar
				placeholder={t('productsPayment.searchPlaceholder', 'Search Products')}
			/>*/}

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center flex flex-col gap-4 pt-4 sm:mb-4 ml-[14px] sm:ml-5 xl:ml-0">
				<SypacBreadcrumbs>
					<div
						className="cursor-pointer text-cornflower-blue"
						onClick={() => navigate('/store/groups')}
					>
						<T keyName="clientSelectQuantity.store">Store</T>
					</div>
					<div className="sm:hidden">...</div>
					<div
						className="hidden sm:block cursor-pointer text-cornflower-blue"
						onClick={() =>
							navigate(`/store/groups/${currentGroup?.id}/categories`)
						}
					>
						{currentGroup?.name}
					</div>
					<div
						className="hidden sm:block cursor-pointer text-cornflower-blue"
						onClick={() =>
							navigate(
								`/store/groups/${currentGroup?.id}/categories/${order?.category?.id}/products`,
							)
						}
					>
						{currentCategory?.name}
					</div>
					<div className="text-cornflower-blue">{currentProduct?.name}</div>
				</SypacBreadcrumbs>
				<div className="flex items-center gap-4">
					<div className="hidden sm:flex gap-4">
						<span className="scale-[1.2]">
							<MapIcon green={true} />
						</span>
						<SypacText variant="body-regular-medium">
							<p className="align-middle text-gray-80">
								<T keyName="clientSelectQuantity.deliveryAddress">
									Delivery address:
								</T>
							</p>
						</SypacText>
					</div>

					<span className="mt-[2px]">
						<Flag
							className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
							code={order?.location?.countryCode?.toLocaleUpperCase()}
							width={24}
							height={16}
						/>
					</span>
					<SypacText variant="body-regular-medium">
						<p className="align-middle text-gray-80">{address}</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<a
							href="/store"
							className="flex flex-row justify-center items-center h-[22px] w-[22px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-md"
						>
							<Close width="10" height="10" />
						</a>
					</SypacButton>
				</div>
			</div>
			<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center sm:py-3 ml-[14px] sm:ml-5 xl:ml-0">
				<ClientStepper currentStep={3} />
			</div>
			<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

			<div className="sm:w-[calc(100vw-154px)] xl:w-[1133px] xl:self-center flex flex-col sm:flex-row gap-6 sm:justify-between mb-6 sm:mb-0 mt-[10px] xl:mt-[15px] sm:ml-5 sm:mr-7.5 xl:mx-0">
				<Payments
					onSuccess={onSuccess}
					currentProduct={currentProduct!}
					prices={prices}
					quantity={quantity}
				/>

				<div className="hidden sm:inline-table sm:w-[418px] xl:w-[460px] flex-shrink-0">
					<ClientOrderSummary
						currentProduct={currentProduct!}
						prices={prices}
						quantity={quantity}
						step={3}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductsPayment;
