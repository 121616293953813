import React, { useCallback, useContext, useMemo, useState } from 'react';
import { QuantityCounterProps } from './QuantityCounter.interface';
import { SypacBox, SypacText } from '@sypac/component-library-react';
import './quantity-counter.css';
import {
	QuantityPickerContext,
	QuantityProps,
} from '../../context/QuantityPickerContext/quantity-picker.context';
import { T } from '@tolgee/react';
import { DumpTruck } from '../../assets/DumpTruck';
import { SemiTrailer } from '../../assets/SemiTrailer';
import { Hooklifter } from '../../assets/Hooklifter';
import { DecreaseIcon } from '../../assets/DecreaseIcon';
import { IncreaseIcon } from '../../assets/IncreaseIcon';

const QuantityCounter = (props: QuantityCounterProps) => {
	const { car, payload, id } = props.counter;
	const [hoverStates, setHoverStates] = useState({
		decrease: false,
		increase: false,
	});

	const {
		totalQuantityCounter,
		inputQuantity,
		setTotalQuantityCounter,
		setInputQuantity,
	} = useContext(QuantityPickerContext);

	const currentQuantity = useMemo(
		() => totalQuantityCounter?.find((item) => item.type === id),
		[id, totalQuantityCounter],
	);
	const [counter, setCounter] = useState(currentQuantity?.quantity || 0);
	const [payloads, setPayload] = useState(currentQuantity?.payload || 0);

	const updateQuantity = useCallback(
		(data: { payload: number; quantity: number; type: string }) => {
			const exist = totalQuantityCounter?.find((item) => item.type === id);
			let newTotals: QuantityProps[] | undefined = totalQuantityCounter?.length
				? totalQuantityCounter
				: [];
			if (exist) {
				const localTotals = totalQuantityCounter
					?.map((item) => {
						if (item.type === id) {
							return data.quantity ? data : null;
						}
						return item;
					})
					.filter((item) => !!item);
				newTotals = (localTotals as QuantityProps[]) || [];
			} else {
				newTotals = [...newTotals, data];
			}
			if (inputQuantity) {
				setInputQuantity(0);
			}
			setTotalQuantityCounter(newTotals);
		},
		[
			id,
			inputQuantity,
			setInputQuantity,
			setTotalQuantityCounter,
			totalQuantityCounter,
		],
	);

	const count = useCallback(
		(action: string) => {
			if (action === 'increase') {
				// const value = totalQuantityCounter?.reduce((t, r) => t + r.payload, 0);
				// if (
				// 	availableQuantity &&
				// 	value &&
				// 	!unlimited &&
				// 	availableQuantity < value + payload
				// ) {
				// 	toastVariant(
				// 		t(
				// 			`quantityPicker.exceedsTheAvailableQuantity`,
				// 			'Exceeds the available quantity',
				// 		),
				// 		true,
				// 	);
				// 	return;
				// }
				setCounter(counter + 1);
				setPayload(payload);
				updateQuantity({
					payload: payload,
					type: id,
					quantity: counter + 1,
				});
			}
			if (action === 'decrease') {
				if (counter === 0) {
					return;
				}
				setCounter(counter - 1);
				setPayload(payload);
				updateQuantity({
					payload: payload,
					type: id,
					quantity: counter - 1,
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[counter, id, payload, payloads, updateQuantity],
	);

	const TruckIcon = ({ type }: { type: string }) => {
		switch (type) {
			case 'aluminium':
				return <DumpTruck />;
			case 'steel':
				return <SemiTrailer />;
			case 'hooklifter':
				return <Hooklifter />;
			default:
				return null;
		}
	};

	return (
		<div className="flex justify-between items-center">
			<div className="flex gap-4 sm:gap-6">
				<div
					className={`quantity-counter-bg-image ${
						counter > 0 ? 'shadow-md' : 'bg-light-grey'
					}`}
				>
					<span className="scale-[0.8] sm:scale-[1]">
						<TruckIcon type={car} />
					</span>
				</div>
				<div className="flex flex-col gap-1 sm:gap-2.5">
					<SypacText variant="body-regular-large">
						<p className="text-base sm:text-[20px] leading-[26px] text-gray-90">
							<T keyName={`quantityPicker.${car}`}>
								{car.charAt(0).toUpperCase() + car.slice(1)}
							</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-small">
						<p className="text-gray-40">
							<T keyName="quantityPicker.payload">Payload:</T> {payload}{' '}
							<T keyName="quantityCounter.tons">tons</T>
						</p>
					</SypacText>
				</div>
			</div>

			<SypacBox boxStyle="none" className="w-fit xl:w-[172px]" hover={false}>
				<div className="flex gap-5 xl:justify-between items-center">
					<button
						className="quantity-button"
						type="button"
						onClick={() => count('decrease')}
						onMouseEnter={() =>
							setHoverStates({ ...hoverStates, decrease: true })
						}
						onMouseLeave={() =>
							setHoverStates({ ...hoverStates, decrease: false })
						}
					>
						<DecreaseIcon isActive={hoverStates.decrease} />
					</button>
					<SypacText
						variant="body-regular-medium"
						className="flex items-center justify-center"
					>
						<p
							className={`${
								counter === 0 ? 'text-gray-40' : 'text-xl text-primary-violet'
							} w-[35px] text-center`}
						>
							{counter}
						</p>
					</SypacText>
					<button
						className="quantity-button"
						type="button"
						onClick={() => count('increase')}
						onMouseEnter={() =>
							setHoverStates({ ...hoverStates, increase: true })
						}
						onMouseLeave={() =>
							setHoverStates({ ...hoverStates, increase: false })
						}
					>
						<IncreaseIcon isActive={hoverStates.increase} />
					</button>
				</div>
			</SypacBox>
		</div>
	);
};

export default QuantityCounter;
