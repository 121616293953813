/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import {
	SypacBadge,
	SypacBreadcrumbs,
	SypacButton,
	SypacLink,
	SypacText,
} from '@sypac/component-library-react';
import dayjs from 'dayjs';
import { T, useTranslate } from '@tolgee/react';
import Flag from 'react-world-flags';
import { ProducersProductCardInterface } from './ProducersProductCard.interface';
import './producers-product-card.css';
import { StatusColors } from '../../../constants/order-status.constants';
import ManageProduct from '../../ManageProduct/ManageProduct';
import { ProductInterface } from '../../../interfaces/product.interface';

export const ProducersProductCard = (props: ProducersProductCardInterface) => {
	const { t } = useTranslate();
	const [showManageProduct, setManageProduct] = useState<boolean>(false);
	const [product, setProduct] = useState<Partial<ProductInterface>>(props.data);

	const badge = useMemo(
		() => StatusColors[product.status!] || StatusColors.pending,
		[product.status],
	);

	const editProduct = (data: ProductInterface) => {
		setProduct({ ...product, ...data });
		setManageProduct(false);
	};

	return (
		<section className="relative h-full w-full rounded-[10px] hover:bg-gray-1 producer-card-border-left">
			<div className="flex flex-col">
				<div className="flex flex-row justify-between border-bottom-solid border-b-gray-10 items-center bg-gray-1 rounded-t-[9px] py-5 pl-8 pr-6">
					<div className="flex flex-row gap-10 items-center">
						<SypacText variant="body-normal-medium">
							<p className="text-gray-80">
								<T keyName="producersProductCard.productId">Product ID:</T>{' '}
								{product.id}
							</p>
						</SypacText>
						<SypacText variant="body-regular-large">
							<p className="text-gray-80">|</p>
						</SypacText>

						<SypacText variant="body-normal-medium">
							<p className="text-gray-80">
								{dayjs(product.createdAt).format('DD MMM, YYYY — HH:mm')}
							</p>
						</SypacText>
					</div>
					{/* <div className="flex flex-row items-center gap-2.5">
						<SypacText variant="body-normal-medium">
							<p className="text-gray-80">
								<T keyName="producersProductCard.addedBy">Added by:</T>
							</p>
						</SypacText>
						<SypacAvatar size="sm" initials={product.createdByUser?.name}>
							<img
								src={product.createdByUser?.avatarUrl}
								alt={product.createdByUser?.name}
							/>
						</SypacAvatar>
						<SypacText variant="body-normal-medium">
							<p className="text-gray-80">{product.createdByUser?.name}</p>
						</SypacText>
					</div> */}
				</div>
				<div className="h-full py-6 pl-8 pr-6">
					<div className="grid grid-cols-11 gap-9">
						<div
							className="h-full bg-gray-10 w-full sm:col-span-5 lg:col-span-3 rounded-xl bg-no-repeat bg-cover"
							style={{ backgroundImage: `url(${product.photoUrl})` }}
						/>
						<div className="flex flex-col sm:col-span-6 lg:col-span-8 gap-7.5">
							<div className="flex flex-col gap-3">
								<div className="flex flex-row gap-5 items-center">
									<SypacText variant="heading-5">
										<p className="font-semibold">{product.name}</p>
									</SypacText>
									<SypacBadge
										color={badge.color}
										outlined={false}
										className="ml-5"
									>
										<div className="text-xs font-medium whitespace-nowrap min-w-[113px] flex justify-center">
											<T keyName={badge.label}>{product.status}</T>
										</div>
									</SypacBadge>
								</div>
								<SypacBreadcrumbs>
									<SypacLink variant="breadcrumbs">
										<a href="#">
											{product.category?.parent?.name ||
												t(
													'producersProductCard.defaultMainCategory',
													'Granular materials',
												)}
										</a>
									</SypacLink>
									<SypacLink variant="breadcrumbs">
										<a href="#">{product.category?.name}</a>
									</SypacLink>
								</SypacBreadcrumbs>
							</div>
							<div className="sm:flex flex-col lg:grid grid-cols-2">
								<div className="grid grid-cols-5 gap-3">
									<div className="flex flex-col col-span-2">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">
												<T keyName="producersProductCard.placedId">
													Placed in:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="flex flex-col col-span-3">
										<div className="flex flex-row">
											{product.countryCode ? (
												<Flag
													className="rounded-[3px]"
													code={product.countryCode}
													width={22}
													height={16}
												/>
											) : null}
											<SypacText
												variant="body-normal-medium"
												className={product.countryCode ? 'ml-5' : ''}
											>
												<p className="text-gray-80">{product.address}</p>
											</SypacText>
										</div>
									</div>

									<div className="flex flex-col col-span-2">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">
												<T keyName="producersProductCard.availableQuantity">
													Available quantity:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="flex flex-col col-span-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												{product.unlimited
													? 'Unlimited'
													: product.availableQuantity}{' '}
												{product.unlimited ? null : (
													<T keyName="producersProductCard.tons">tons</T>
												)}
											</p>
										</SypacText>
									</div>

									<div className="flex flex-col col-span-2">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">
												<T keyName="producersProductCard.price">Price:</T>
											</p>
										</SypacText>
									</div>
									<div className="flex flex-col col-span-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												{product?.pricePerUnit?.toFixed(2)} zł —
												<T keyName="producersProductCard.perTon">per ton</T>
											</p>
										</SypacText>
									</div>

									{/* <div className="flex flex-col col-span-2">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">
												<T keyName="producersProductCard.sold">Sold:</T>
											</p>
										</SypacText>
									</div> */}
									{/* <div className="flex flex-col col-span-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												4 000.20 <T keyName="producersProductCard.tons">tons</T>
											</p>
										</SypacText>
									</div> */}
								</div>
								<div className="grid grid-cols-5">
									{/* <div className="flex flex-col col-span-2 gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">
												<T keyName="producersProductCard.revenue">Revenue:</T>
											</p>
										</SypacText>
									</div>
									<div className="flex flex-col col-span-3 gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">4 000 zł</p>
										</SypacText>
									</div> */}

									<div className="flex flex-col col-span-2 gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">
												<T keyName="producersProductCard.totalOrders">
													Total orders:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="flex flex-col col-span-2 gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">1</p>
										</SypacText>
									</div>

									{/* <div className="flex flex-col col-span-2 gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">
												<T keyName="producersProductCard.productType">
													Product type:
												</T>
											</p>
										</SypacText>
									</div> */}
									{/* <div className="flex flex-col col-span-2 gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">{product.type}</p>
										</SypacText>
									</div> */}

									<div className="flex flex-col col-span-2 gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">
												<T keyName="producersProductCard.productStock">
													Product stock:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="flex flex-col col-span-2 gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">Available</p>
										</SypacText>
									</div>
								</div>
							</div>
							<SypacButton
								className="text-white w-fit"
								variant="primary"
								size="small"
								onClick={() => setManageProduct(true)}
							>
								<a>
									<T keyName="producersProductCard.manageProduct">
										Manage Product
									</T>
								</a>
							</SypacButton>
						</div>
					</div>
				</div>
			</div>{' '}
			{showManageProduct ? (
				<ManageProduct
					isOpen={showManageProduct}
					onClose={() => setManageProduct(false)}
					onSubmit={editProduct}
					product={product}
				/>
			) : null}
		</section>
	);
};
