import React, { useContext, useEffect, useRef, useState } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { Document, Page } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import Close from '../../assets/Close';
import { OrderContext } from '../../context/OrderContext/order.context';
import { T } from '@tolgee/react';
import Flag from 'react-world-flags';

export interface DocumentsViewerProps {
	isOpen: boolean;
	url: string;
	isPdf: boolean;
	onClose: () => void;
	additional?: {
		isLocation?: boolean;
		text: string;
		updatedAt?: string;
		deliveryCode?: string;
	};
}

const DocumentsViewer = ({
	isOpen,
	onClose,
	url,
	isPdf,
	additional,
}: DocumentsViewerProps) => {
	const { setAdditional } = useContext(OrderContext);
	const [nrChanges, setNrChanges] = useState(false);
	const [numPages, setNumPages] = useState(1);
	const [docsUrl, setDocsUrl] = useState<string>();
	const modalRef = useRef<HTMLDivElement>(null);

	const onLoadSuccess = (document: DocumentCallback) => {
		if (!nrChanges) {
			setNumPages(document?._pdfInfo?.numPages || 1);
			setNrChanges(true);
		}
	};

	useEffect(() => {
		setDocsUrl(url);
	}, [url]);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<div className="modal-overlay" onClick={() => onClose()}>
			<div
				className="absolute bg-transparent outline-none w-[924px] h-[860px]"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<div className="flex h-full gap-5 justify-center">
					<div
						className={`box-border relative bg-white border border-solid border-gray-10 rounded-2xl ${
							!isPdf ? 'w-full h-full' : 'p-1'
						}`}
					>
						{isPdf ? (
							<Document
								file={docsUrl}
								renderMode="canvas"
								onLoadSuccess={onLoadSuccess}
							>
								{Array(numPages)
									.fill(0)
									.map((r, i) => {
										return (
											<Page
												pageNumber={i + 1}
												renderTextLayer={false}
												key={i}
											/>
										);
									})}
							</Document>
						) : (
							<img
								src={docsUrl}
								alt="company document"
								className="w-full h-full object-cover rounded-2xl"
							/>
						)}
						{additional ? (
							!!additional.isLocation ? (
								<div className="box-border absolute w-[859px] bottom-0 flex flex-col gap-2.5 p-5 bg-[#fefefe] border border-solid border-gray-10 rounded-2xl z-50">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40">
											<T keyName="pickUpDeliveryDetails.deliveryLocation">
												Delivery location:
											</T>
										</p>
									</SypacText>

									<div className="flex items-center gap-2">
										<span className="-ml-[2px] mt-[2px]">
											<Flag
												className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
												code={additional.deliveryCode}
												width={24}
												height={16}
											/>
										</span>
										<SypacText variant="body-regular-medium">
											<p className="text-2xl text-gray-90">{additional.text}</p>
										</SypacText>
									</div>
								</div>
							) : (
								<div className="box-border absolute w-[859px] bottom-0 flex flex-col gap-2.5 p-5 bg-[#fefefe] border border-solid border-gray-10 rounded-2xl z-50">
									<SypacText variant="body-regular-medium">
										<p className="text-2xl text-gray-80">{additional?.text}</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40">
											{new Date(additional?.updatedAt!)
												.toLocaleDateString('en-GB', {
													day: '2-digit',
													month: 'short',
													year: 'numeric',
												})
												.replace(/ (\d{4})$/, ', $1')}
										</p>
									</SypacText>
								</div>
							)
						) : null}
					</div>

					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[44px] w-[44px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-80 hover:border-[2px] rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>
			</div>
		</div>
	) : null;
};

export default DocumentsViewer;
