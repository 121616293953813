import React, { useEffect, useMemo, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	CommonNotificationsEnums,
	OrderInterface,
} from '../../../../../components/OrdersTable/interfaces/Order.interface';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { DumpTruck } from '../../../../../assets/DumpTruck';
import { SemiTrailer } from '../../../../../assets/SemiTrailer';
import { Hooklifter } from '../../../../../assets/Hooklifter';
import { Calendar } from '../../../../../assets/Calendar';
import dayjs from 'dayjs';
import Flag from 'react-world-flags';
import { MapIcon } from '../../../../../assets/MapIcon';
import TooltipCircle from '../../../../../assets/TooltipCircle';
import ModalMap from '../../../../../components/ModalMap/ModalMap';
import StatusBadge from '../../../../../components/StatusBadge/StatusBadge';
import { useGetCompanies } from '../../../../../hooks/use-get-companies';
import { Tooltip } from 'react-tooltip';
import { OrderStatesEnum } from '../../../../../services/orders.services';
import { Clock } from '../../../../../assets/Clock';
import { convertToTimeZone } from '../../../../../utils/time.util';
import CarrierCompanyTruck from '../../../../../assets/CarrierCompanyTruck';

interface FulfilmentScheduleProps {
	selectedOrder: OrderInterface | undefined;
	step: number;
	deliveryIncluded: boolean;
	simpleOrder: boolean;
}

const FulfilmentSchedule: React.FC<FulfilmentScheduleProps> = (props) => {
	const { t } = useTranslate();
	const { selectedOrder, step, deliveryIncluded, simpleOrder } = props;
	const [companyIds, setCompanyIds] = useState<number[]>();
	const [openMap, setOpenMap] = useState<boolean>(false);
	const [currentTruckStatus, setCurrentTruckStatus] = useState<string>('');

	const orderTrucks = useMemo(() => {
		return selectedOrder?.trucks;
	}, [selectedOrder?.trucks]);

	const isPickUpDate = (status: string) => {
		const pickUpDateStatuses = [
			'requested',
			'accepted',
			'to_loading',
			'arrived_to_loading',
			'loading',
		];
		return pickUpDateStatuses.includes(status);
	};

	const variationPercent = (weight: number, quantity: number) => {
		const result = ((weight - quantity) / quantity) * 100 || 0;
		const percent = Number.isInteger(result)
			? result
			: parseFloat(result.toFixed(2));
		return result > 0 ? `+${percent}` : `${percent}`;
	};

	useEffect(() => {
		const ids =
			orderTrucks?.map((truck) => truck.driver?.companyId).filter(Boolean) ||
			[];
		setCompanyIds(ids);
	}, [orderTrucks]);

	const [companies] = useGetCompanies({ ids: companyIds! });

	const TruckIcon = ({ type }: { type: string }) => {
		switch (type) {
			case 'aluminium':
				return <DumpTruck />;
			case 'steel':
				return <SemiTrailer />;
			case 'hooklifter':
				return <Hooklifter />;
			default:
				return null;
		}
	};

	const InfoRow = ({ label, value, children, className = '' }: any) => (
		<div className={`grid grid-cols-[250px,auto] items-start ${className}`}>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40">{label}</p>
			</SypacText>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-80">{children || value}</p>
			</SypacText>
		</div>
	);

	const referenceFormatter = (id: string) => {
		return id.substring(0, 3) + ' ' + id.substring(3);
	};

	const openViewOnMap = (status: string) => {
		setCurrentTruckStatus(status);
		setOpenMap(true);
	};

	const closeViewOnMap = () => {
		setCurrentTruckStatus('');
		setOpenMap(false);
	};

	const renderNotification = (
		category: string,
		label: string,
		driverId?: string,
	) => {
		const notification = selectedOrder?.notifications?.items?.find(
			(notify) =>
				notify.category === category &&
				(driverId ? notify.content?.driverId === driverId : true),
		);

		if (!notification) return null;

		const { createdAt } = notification;
		const formattedDate = dayjs(createdAt).format('DD MMM. YYYY');
		const formattedTime = convertToTimeZone(createdAt);

		return (
			<div className="grid grid-cols-[250px,auto] items-start">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">{label}:</p>
				</SypacText>
				<div className="flex flex-col gap-3">
					<div className="flex gap-6 [&_p]:text-gray-80">
						<div className="flex gap-3">
							<span className="scale-[1.1]">
								<Calendar />
							</span>
							<SypacText variant="body-regular-medium">
								<p>{formattedDate}</p>
							</SypacText>
						</div>
						<div className="flex gap-3">
							<span className="scale-[1.1]">
								<Clock />
							</span>
							<SypacText variant="body-regular-medium">
								<p>{formattedTime}</p>
							</SypacText>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="bg-white border border-solid border-gray-10 rounded-xl">
				<SypacText variant="body-regular-medium" className="px-5 pt-4 mb-4">
					<p className="text-gray-60">
						{deliveryIncluded ? (
							selectedOrder?.state === OrderStatesEnum.AWAITING_CONFIRMATION ? (
								'Matched solutions'
							) : (
								<T keyName="fulfilmentSchedule.fulfilmentSchedule">
									Fulfilment schedule
								</T>
							)
						) : (
							<T keyName="deliverySchedule.pickUpSchedule">Pick-up schedule</T>
						)}
					</p>
				</SypacText>

				<div className="flex flex-col gap-4 pb-4">
					{orderTrucks?.map((orderTruck, index) => (
						<div className="flex flex-col gap-4" key={orderTruck?.id}>
							<div className="flex gap-4 px-5">
								<span className="w-[98px] h-[53px] flex items-center justify-center bg-alabaster border border-solid border-gray-10 rounded-[10px]">
									<TruckIcon type={orderTruck?.truckType} />
								</span>
								<div className="flex flex-col gap-[5px] justify-center">
									<div className="flex gap-4">
										<SypacText variant="body-regular-medium">
											<p className="leading-[26px] text-gray-90">
												{orderTruck?.truckType?.charAt(0).toUpperCase()! +
													orderTruck?.truckType?.slice(1)}
											</p>
										</SypacText>

										{!simpleOrder ? (
											<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
												<SypacText variant="body-regular-medium">
													<p className="text-sm text-gray-90">
														<T keyName="requestedStep.payloadTruck">Truck</T>{' '}
														{index + 1}
													</p>
												</SypacText>
											</div>
										) : null}
									</div>
									<SypacText variant="body-regular-small">
										<p className="text-gray-40">
											<T keyName="quantityPicker.payload">Payload:</T>{' '}
											{orderTruck?.payload}{' '}
											<T keyName="quantityCounter.tons">tons</T>
										</p>
									</SypacText>
								</div>

								{orderTruck?.orderStatus ? (
									<div className="h-[22px] ml-auto">
										<StatusBadge
											status={orderTruck?.orderStatus!}
											isTruckStatus={true}
											deliveryIncluded={deliveryIncluded}
										/>
									</div>
								) : null}
							</div>

							<div className="border-0 border-solid border-t-[1px] border-gray-10" />
							<div className="flex flex-col gap-3 px-5">
								{!simpleOrder &&
								selectedOrder?.notifications?.items?.some(
									(notify) =>
										notify.category ===
											CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS &&
										notify.content?.driverId === orderTruck?.driver?.uid,
								) ? (
									<div className="grid grid-cols-[250px,auto] items-start">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-40">
												<T keyName="basicDetails.deliveryDate">
													Delivery date:
												</T>
											</p>
										</SypacText>

										<div className="flex flex-col gap-3">
											<div className="flex gap-6 [&_p]:text-gray-80">
												<div className="flex gap-3">
													<span className="scale-[1.1]">
														<Calendar />
													</span>
													<SypacText variant="body-regular-medium">
														<p>
															{dayjs(
																selectedOrder?.notifications?.items?.find(
																	(notify) =>
																		notify.category ===
																			CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS &&
																		notify.content?.driverId ===
																			orderTruck?.driver?.uid,
																)?.createdAt,
															).format('DD MMM,. YYYY')}
														</p>
													</SypacText>
												</div>

												<div className="flex gap-3">
													<span className="scale-[1.1]">
														<Clock />
													</span>
													<SypacText variant="body-regular-medium">
														<p>
															{convertToTimeZone(
																selectedOrder?.notifications?.items?.find(
																	(notify) =>
																		notify.category ===
																			CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS &&
																		notify.content?.driverId ===
																			orderTruck?.driver?.uid,
																)?.createdAt!,
															)}
														</p>
													</SypacText>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className="grid grid-cols-[250px,auto] items-start mb-4">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-40">
												{!deliveryIncluded ||
												isPickUpDate(orderTruck.orderStatus) ? (
													<T keyName="clientDeliveryDetails.scheduledPickUpDate">
														Scheduled pick-up date:
													</T>
												) : (
													<T keyName="clientDeliveryDetails.deliveryDate">
														Scheduled delivery date:
													</T>
												)}
											</p>
										</SypacText>
										<div className="flex flex-col gap-3">
											<div className="flex gap-3 [&_p]:text-gray-80">
												<span className="scale-[1.1]">
													<Calendar />
												</span>
												{!deliveryIncluded ||
												isPickUpDate(orderTruck.orderStatus) ? (
													<SypacText variant="body-regular-medium">
														<p>
															{dayjs(orderTruck?.deliveryFrom).format(
																'DD MMM. YYYY',
															)}
														</p>
													</SypacText>
												) : (
													<SypacText variant="body-regular-medium">
														<p>
															{dayjs(orderTruck?.deliveryTo).format(
																'DD MMM. YYYY',
															)}
														</p>
													</SypacText>
												)}
											</div>
											<div className="flex gap-3 [&_p]:text-gray-80">
												<span className="scale-[1.1]">
													<Clock />
												</span>
												<div className="flex gap-5 items-center">
													{selectedOrder?.trucks ? (
														convertToTimeZone(orderTruck.deliveryTimeFrom) ===
															'0:00' &&
														convertToTimeZone(orderTruck.deliveryTimeTo) ===
															'23:59' ? (
															<SypacText variant="body-regular-medium">
																<p>Full Time</p>
															</SypacText>
														) : (
															<>
																<SypacText variant="body-regular-medium">
																	<p>
																		{convertToTimeZone(
																			orderTruck.deliveryTimeFrom,
																		)}
																	</p>
																</SypacText>
																<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
																<SypacText variant="body-regular-medium">
																	<p>
																		{convertToTimeZone(
																			orderTruck.deliveryTimeTo,
																		)}
																	</p>
																</SypacText>
															</>
														)
													) : null}
												</div>
											</div>
										</div>
									</div>
								)}

								{(selectedOrder?.state ===
									OrderStatesEnum.AWAITING_CONFIRMATION ||
									(!simpleOrder &&
										deliveryIncluded &&
										!orderTruck?.orderStatus)) && (
									<InfoRow label={t('truckDetails.Status', 'Status:')}>
										<StatusBadge
											status={orderTruck?.orderStatus!}
											isTruckStatus
											deliveryIncluded={deliveryIncluded}
										/>
									</InfoRow>
								)}

								{deliveryIncluded &&
									step > 6 &&
									simpleOrder &&
									(selectedOrder?.notifications?.items?.find(
										(obj) =>
											obj.category ===
											CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS,
									) ? (
										<div className="grid grid-cols-[250px,auto] items-start">
											<SypacText variant="body-regular-medium">
												<p className="text-gray-40">
													<T keyName="basicDetails.deliveryDate">
														Delivery date:
													</T>
												</p>
											</SypacText>

											<div className="flex gap-3">
												<span className="scale-[1.1]">
													<Calendar />
												</span>
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">
														{dayjs(
															selectedOrder?.notifications?.items?.find(
																(obj) =>
																	obj.category ===
																	CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS,
															)?.createdAt,
														).format('DD MMM. YYYY')}
													</p>
												</SypacText>
											</div>
										</div>
									) : (
										<InfoRow
											label={t(
												'truckDetails.estimatedDateOfArrival',
												'Estimated date of arrival:',
											)}
										>
											<div className="flex gap-3 [&_p]:text-gray-80">
												<span className="scale-[1.1]">
													<Calendar />
												</span>
												<SypacText variant="body-regular-medium">
													<p>
														{dayjs(
															selectedOrder?.trucks?.[0].deliveryTo,
														).format('DD MMM. YYYY')}
													</p>
												</SypacText>
											</div>
										</InfoRow>
									))}

								{simpleOrder &&
									!deliveryIncluded &&
									selectedOrder?.notifications?.items?.some(
										(notify) =>
											notify.category ===
											CommonNotificationsEnums.ORDER_START_LOADING_GOODS,
									) && (
										<div className="flex flex-col gap-3 mb-4">
											{renderNotification(
												CommonNotificationsEnums.ORDER_START_LOADING_GOODS,
												t(
													'deliverySchedule.truckArrivalConfirmation',
													'Truck arrival confirmation',
												),
											)}
											{renderNotification(
												CommonNotificationsEnums.ORDER_COMPLETE,
												t(
													'clientDeliveryDetails.loadingConfirmation',
													'Loading confirmation',
												),
											)}
										</div>
									)}

								{orderTruck.driver && deliveryIncluded && (
									<div className="grid grid-cols-[250px,auto] items-start">
										<SypacText variant="body-regular-medium">
											{isPickUpDate(orderTruck.orderStatus) ? (
												<p className="text-gray-40">
													<T keyName="basicDetails.pickUpAddress">
														Pick-up address:
													</T>
												</p>
											) : (
												<p className="text-gray-40">
													<T keyName="baseOrder.deliveryAddress">
														Delivery address:
													</T>
												</p>
											)}
										</SypacText>
										<div className="flex flex-col gap-3">
											<div className="flex items-center gap-2">
												<span className="-ml-[2px] mt-[2px]">
													<Flag
														className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
														code={
															isPickUpDate(orderTruck.orderStatus)
																? selectedOrder?.pickupCountryCode
																: selectedOrder?.deliveryCountryCode
														}
														width={24}
														height={16}
													/>
												</span>
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80 w-[296px] truncate">
														{isPickUpDate(orderTruck.orderStatus)
															? selectedOrder?.pickupAddress
															: selectedOrder?.deliveryAddress}
													</p>
												</SypacText>
											</div>
											<div className="flex items-center gap-2">
												<span className="-ml-[2px] mr-[2px] scale-[1.2]">
													<MapIcon />
												</span>
												<SypacButton variant="secondary" size="small">
													<button
														className="w-full border-none bg-transparent p-0 group"
														onClick={() =>
															openViewOnMap(orderTruck.orderStatus)
														}
														type="button"
													>
														<SypacText variant="body-normal-medium">
															<p className="text-cornflower-blue transition group-hover:text-gray-80">
																<T keyName="orderDetails.viewLocationOnMap">
																	View location on the map
																</T>
															</p>
														</SypacText>
													</button>
												</SypacButton>
											</div>
										</div>
									</div>
								)}

								<InfoRow
									label={
										deliveryIncluded
											? t('assignOrder.referenceId', 'Reference ID:')
											: t('deliverySchedule.pickUpId:', 'Pick-up reference ID:')
									}
								>
									<div className="flex items-center gap-3">
										<SypacText variant="body-regular-medium">
											{orderTruck.driver || !deliveryIncluded ? (
												<p className="text-cornflower-blue font-bold">
													{referenceFormatter(orderTruck.referenceId!)}
												</p>
											) : (
												<p className="text-gray-80">
													<T keyName="deliverySchedule.pendingAllocation">
														Pending allocation
													</T>
												</p>
											)}
										</SypacText>
										<span
											data-tooltip-id="fulfilment-schedule"
											data-tooltip-content={t(
												'truckSchedule.referenceId',
												'Truck Reference ID: A unique code',
											)}
											className="p-0 m-0 mb-[2px] flex scale-[1.1]"
										>
											<TooltipCircle />
										</span>
									</div>
								</InfoRow>

								{!deliveryIncluded ? (
									<>
										<InfoRow
											label={t('truckDetails.driverName', 'Driver name:')}
											value={orderTruck.driver?.driverFullName || '-'}
										/>
										<InfoRow
											label={t('truckDetails.licensePlates', 'License plates:')}
											value={orderTruck.driver?.licensePlates || '-'}
										/>
									</>
								) : null}

								{orderTruck.driver && deliveryIncluded ? (
									<>
										<InfoRow
											label={t(
												'truckDetails.orderedQuantity',
												'Ordered quantity:',
											)}
											value={`${orderTruck?.payload} tons`}
											className={!orderTruck?.loadedQuantity ? 'mb-4' : ''}
										/>

										{orderTruck?.loadedQuantity && (
											<InfoRow
												label={t(
													'baseOrderDetails.loadedQuantity',
													'Loaded quantity:',
												)}
												className="mb-4"
											>
												<div className="flex gap-3">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80 font-bold">
															{orderTruck?.loadedQuantity % 1 === 0
																? orderTruck?.loadedQuantity
																: orderTruck?.loadedQuantity.toFixed(2)}{' '}
															<T keyName="basicDetails.tons">tons</T>
														</p>
													</SypacText>
													<span className="flex items-center bg-gray-10 rounded px-1">
														<SypacText variant="body-regular-medium">
															<p className="text-xs leading-none">
																{variationPercent(
																	orderTruck.loadedQuantity,
																	orderTruck.payload,
																)}
																%
															</p>
														</SypacText>
													</span>
													<SypacText
														variant="body-regular-medium"
														className="self-center"
													>
														<p className="text-sm text-gray-40 leading-none">
															<T keyName="baseOrderDetails.quantityDifference">
																Quantity difference
															</T>
														</p>
													</SypacText>
												</div>
											</InfoRow>
										)}

										<InfoRow label={t('truckDetails.Carrier', 'Carrier:')}>
											<div className="flex flex-col gap-2">
												<div className="flex gap-3 items-center">
													<span className="flex ml-[2px] mr-[6px] scale-[1.2]">
														<CarrierCompanyTruck />
													</span>
													<SypacText variant="body-normal-medium">
														<p className="text-cornflower-blue w-[296px] truncate">
															{companies?.[0].name}
														</p>
													</SypacText>
												</div>
											</div>
										</InfoRow>
										<InfoRow
											label={t('truckDetails.driverName', 'Driver name:')}
											value={orderTruck.driver?.driverFullName}
										/>
										<InfoRow
											label={t('truckDetails.licensePlates', 'License plates:')}
											value={orderTruck.driver?.licensePlates || '-'}
										/>
										<InfoRow
											label={t('truckDetails.truckMake', 'Truck make:')}
											value={orderTruck.driver?.truckBrand || '-'}
										/>
									</>
								) : null}
							</div>

							{index !== orderTrucks.length - 1 ? (
								<div className="border-0 border-solid border-t-[1px] border-gray-10" />
							) : null}
						</div>
					))}
				</div>
			</div>
			{openMap ? (
				<ModalMap
					isOpen={openMap}
					onClose={() => closeViewOnMap()}
					markers={[
						{
							latitude: isPickUpDate(currentTruckStatus)
								? selectedOrder?.pickupLocation?.coordinates?.at(1)!
								: selectedOrder?.deliveryLocation?.coordinates?.at(1)!,
							longitude: isPickUpDate(currentTruckStatus)
								? selectedOrder?.pickupLocation?.coordinates?.at(0)!
								: selectedOrder?.deliveryLocation?.coordinates?.at(0)!,
							variant: isPickUpDate(currentTruckStatus) ? 'pickup' : 'delivery',
							...(isPickUpDate(currentTruckStatus)
								? {
										pickup: {
											pickupAddress: selectedOrder?.pickupAddress!,
											pickupCountryCode: selectedOrder?.pickupCountryCode!,
										},
								  }
								: {
										delivery: {
											deliveryAddress: selectedOrder?.deliveryAddress!,
											deliveryCountryCode: selectedOrder?.deliveryCountryCode!,
										},
								  }),
						},
					]}
				/>
			) : null}
			<Tooltip
				place="top"
				id="fulfilment-schedule"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
					whiteSpace: 'normal',
					maxWidth: '248px',
				}}
			/>
		</>
	);
};

export default FulfilmentSchedule;
