import React, { useState } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { SypacText } from '@sypac/component-library-react';
import dayjs from 'dayjs';
import './styles.scss';
import { PaymentsTable } from '../../../components/PaymentsTable/PaymentsTable';
import BillingTabs from '../../../components/BillingTabs/BillingTabs';
import { useGetStats } from '../../../hooks/use-get-stats';
import { BillingTab } from '../../../components/BillingTabs/BillingTabs.interface';
import Pagination from '../../../components/Pagination/Pagination';
import { LIMIT } from '../../../constants';
import ProducerBillingStatistic from '../../../components/ProducerBillingStatistic/ProducerBillingStatistic';
import { useGetInvoicesLines } from '../../../hooks/use-get-invoices-lines';
import { useGetInvoices } from '../../../hooks/use-get-invoices';
import { InvoiceLinesTable } from '../../../components/InvoiceLinesTable/InvoiceLinesTable';
import { useGetInvoiceLinesStats } from '../../../hooks/use-get-lines-stats';
import OrderDetails from '../OrderDetails';
import { OrderInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import { OrdersService } from '../../../services/orders.services';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { InvoiceInterface } from '../../../components/PaymentsTable/interfaces/Payment.interface';
import { PaymentTypeAction } from '../../../components/PaymentsTable/interfaces/PaymentStatus.interface';
import { T, useTranslate } from '@tolgee/react';
import { PaymentPeriod } from '../../../components/PyamentPeriod';

const TransporterBilling: React.FC = () => {
	const { t } = useTranslate();
	const [searchQuery] = useState<string>();
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [page, setPage] = useState<number>(0);
	const [invoicesPage, setInvoicesPage] = useState<number>(0);
	const [isOpen, setOpenDetails] = useState<boolean>(false);
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);

	const selectTab = (tab: string) => {
		setCurrentTab(tab);
		setPage(0);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [invoicesList, _, invoicesAreLoading] = useGetInvoices({
		limit: LIMIT,
		offset: invoicesPage * LIMIT,
		target: 'transporter',
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [invoicesLines, lerr, linesAreLoading] = useGetInvoicesLines({
		orderId: searchQuery,
		status: currentTab,
		limit: LIMIT,
		offset: page * LIMIT,
		target: 'transporter',
	});

	const [stats] = useGetStats({
		target: 'transporter',
		refresh: 0,
	});
	const [statsLines] = useGetInvoiceLinesStats({ target: 'transporter' });
	const [stats30] = useGetStats({
		target: 'transporter',
		fromDate: dayjs().subtract(30, 'days').format(),
		refresh: 0,
	});

	const [stats60] = useGetStats({
		target: 'transporter',
		fromDate: dayjs().subtract(60, 'days').format(),
		refresh: 0,
	});
	const [stats90] = useGetStats({
		target: 'transporter',
		fromDate: dayjs().subtract(90, 'days').format(),
		refresh: 0,
	});
	const billingStats = {
		amountPaid: stats?.amountTotal?.pending!,
		amountPending: stats?.amount!,
		allOrders: stats?.countOrders?.all!,
		ordersLast30Days: stats30?.countOrders?.all!,
		ordersLast60Days: stats30?.countOrders?.all!,
		paidOrders: stats?.countOrders?.paid!,
		orderInProgress: stats?.countOrders?.inProgress!,
		ordersInDispute: stats?.countOrders?.dispute!,
		revenueLast30Days: stats30?.amountTotal?.all!,
		revenueLast60Days: stats60?.amountTotal?.all!,
		revenueLast90Days: stats90?.amountTotal?.all!,
		paymentTerm: stats?.paymentTerm!,
		nextInvoicingDate: stats?.nextInvoicingDate!,
	};

	const tabs: BillingTab[] = [
		{
			label: t('transporterBilling.all', 'All'),
			value: 'all',
			color: 'white',
			count: statsLines?.all!,
		},
		{
			label: t('transporterBilling.pendingPayment', 'Pending payment'),
			value: 'pending',
			color: 'yellow',
			count: statsLines?.pending!,
		},
		{
			label: t('transporterBilling.paymentDispute', 'Payment dispute'),
			value: 'overdue',
			color: 'red',
			count: statsLines?.overdue!,
		},
		{
			label: t('transporterBilling.paid', 'Paid'),
			value: 'paid',
			color: 'green',
			count: statsLines?.paid!,
		},
	];

	const viewOrder = async (orderId?: number) => {
		try {
			const { data } = await OrdersService.getOrder(
				orderId!,
				OrderTarget.transporter,
			);
			setOrder(data as unknown as OrderInterface);
			setOpenDetails(true);
		} catch (e) {
			console.log(e);
		}
	};

	const aditionalClick = (value: InvoiceInterface | number, type?: string) => {
		if (type === PaymentTypeAction.view_order) {
			viewOrder(value as number);
		}
	};

	return (
		<>
			<div className="flex gap-5 w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-94px)] h-full">
				<div className="w-[900px] flex flex-col gap-3 ml-5">
					<div className="flex justify-between">
						<SypacText variant="heading-4">
							<p className="text-tuna">
								<T keyName="transporterBilling.manageYourWalletBilling">
									Manage your wallet & billing
								</T>
							</p>
						</SypacText>
						{/* <SypacButton variant="secondary" size="small">
							<button className="px-8 py-3">
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="transporterBilling.contactSypac">
											Contact Sypac
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton> */}
					</div>
					<SypacText variant="body-regular-small">
						<p className="text-nevada">
							<T keyName="transporterBilling.downloadInvoicesControlBillingDetails">
								Download invoices and control your billing details.
							</T>
						</p>
					</SypacText>
					{/* <div className="mt-3 w-full">
						<SearchBar
							placeholder={t('transporterBilling.searchOrder', 'Search order')}
							onClick={setSearchQuery}
						/>
					</div> */}
					<BillingTabs
						activeTab={currentTab}
						data={tabs}
						callback={selectTab}
					/>
					{linesAreLoading ? (
						<div className="flex w-full h-full items-center justify-center">
							<MutatingDots
								height="100"
								width="100"
								color="#7693F4"
								secondaryColor="#494C83"
								radius="12.5"
								ariaLabel="mutating-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					) : (
						<div className="h-full border border-solid border-gray-10 rounded-[10px] overflow-hidden whitespace-nowrap">
							<div className="w-full h-[calc(100%-56px)] overflow-y-auto scroll-smooth">
								<InvoiceLinesTable
									target={OrderTarget.transporter}
									rows={invoicesLines?.items || []}
									rowClick={() => {}}
									currentTab={currentTab}
								/>
							</div>
							<div className="bg-white-100 rounded-br-10 rounded-bl-10 border border-gray-10 border-solid w-full shadow-pagination">
								<div className="flex flex-none justify-between py-4 px-7">
									<Pagination
										showText={true}
										count={invoicesLines?.count}
										page={page}
										onClick={(item: any) => setPage(item)}
									/>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="w-[calc(100vw-1036px)] flex flex-col xl:gap-5 mr-7.5">
					<ProducerBillingStatistic {...billingStats} />

					<div className="flex justify-between px-4 pt-2">
						<SypacText variant="body-regular-medium">
							<p>
								<T keyName="paymentsTable.withdrawHistory">Withdraw history</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p>
								<T keyName="paymentsTable.withdrawInterval">
									Withdraw interval:
								</T>{' '}
								<PaymentPeriod value={stats?.paymentTerm!} />
							</p>
						</SypacText>
					</div>

					{invoicesAreLoading ? (
						<div className="flex w-full h-full items-center justify-center">
							<MutatingDots
								height="100"
								width="100"
								color="#7693F4"
								secondaryColor="#494C83"
								radius="12.5"
								ariaLabel="mutating-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					) : (
						<div className="h-full border border-solid border-gray-10 rounded-[10px] overflow-hidden whitespace-nowrap">
							<div className="w-full h-[calc(100%-56px)] overflow-y-auto scroll-smooth">
								<PaymentsTable
									target="withdraw_history"
									rows={invoicesList?.items || []}
									rowClick={() => {}}
									aditionalClick={aditionalClick}
								/>
							</div>
							<div className="bg-white-100 rounded-br-10 rounded-bl-10 border border-gray-10 border-solid w-full shadow-pagination">
								<div className="flex flex-none justify-between py-4 px-7">
									<Pagination
										showText={true}
										count={invoicesList?.count}
										page={invoicesPage}
										onClick={(item: any) => setInvoicesPage(item)}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{isOpen && selectedOrder?.id ? (
				<OrderDetails
					isOpen={!!(isOpen && selectedOrder?.id)}
					selectedOrder={selectedOrder}
				/>
			) : null}
		</>
	);
};

export default TransporterBilling;
