import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../../containers/NotFound';
import TransporterGuardedRoute from '../guards/TransporterGuardedRoute';
import OrdersList from '../../containers/Transporter/OrdersList';
import Fleet from '../../containers/Transporter/Fleet';
import TransporterBilling from '../../containers/Transporter/TransporterBilling';
import { useTranslate } from '@tolgee/react';
import { SideMenu } from '../../components/SideBarMenu/SideMenu';

const TransporterRouter: React.FC = () => {
	const { t } = useTranslate();

	const TRANSPORTER_MENU = [
		{
			logo: 'Document Add',
			alt: t('transporterRouter.orders', 'Orders'),
			count: true,
			url: '/orders',
		},
		{
			logo: 'Bus',
			alt: t('transporterRouter.myFleet', 'My fleet'),
			url: '/fleet',
		},
		{
			logo: 'Money Bag',
			alt: t('transporterRouter.billing', 'Billing'),
			url: '/billing',
		},
	];

	return (
		<Routes>
			<Route
				path="/orders"
				element={
					<TransporterGuardedRoute>
						<SideMenu groups={TRANSPORTER_MENU}>
							<OrdersList />
						</SideMenu>
					</TransporterGuardedRoute>
				}
			/>
			<Route
				path="/fleet"
				element={
					<TransporterGuardedRoute>
						<SideMenu groups={TRANSPORTER_MENU}>
							<Fleet />
						</SideMenu>
					</TransporterGuardedRoute>
				}
			/>
			<Route
				path="/billing"
				element={
					<TransporterGuardedRoute>
						<SideMenu groups={TRANSPORTER_MENU}>
							<TransporterBilling />
						</SideMenu>
					</TransporterGuardedRoute>
				}
			/>
			<Route path="/*" element={<NotFound />} />
		</Routes>
	);
};

export default TransporterRouter;
