import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	SypacBreadcrumbs,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import Flag from 'react-world-flags';
import { useGetCategories } from '../../../hooks/use-get-categories';
import Close from '../../../assets/Close';
import '../select-product.scss';
import { QuantityPickerContext } from '../../../context/QuantityPickerContext/quantity-picker.context';
import QuantityPicker from '../../../components/QuantityPicker/QuantityPicker';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { COUNTERS } from '../constants';
import {
	ProductResponse,
	ProductService,
} from '../../../services/product.services';
import { T } from '@tolgee/react';
import { MapIcon } from '../../../assets/MapIcon';
import ClientStepper from '../../../components/ClientStepper/ClientStepper';
import ClientOrderSummary from '../../../components/ClientOrderSummary/ClientOrderSummary';
import { OrdersService, Prices } from '../../../services/orders.services';
import { GeoService } from '../../../services/geo.services';

// TODO implement products list infinite scroll in client store
const ClientSelectQuantity: React.FC = () => {
	const navigate = useNavigate();
	const { groupId, categoryId, productId } = useParams();
	const [categories] = useGetCategories({ ids: [categoryId!] });
	const [groups] = useGetCategories({});
	const [products, setProducts] = useState<ProductResponse>({
		items: [],
		count: 0,
	});
	const [quantity, setQuantity] = useState(0);
	const { order, setOrder } = useContext(OrderContext);
	const [prices, setPrices] = useState<Prices>({
		productPriceForClient: 0,
		productPriceForClientWithVat: 0,
		transportPriceForClient: 0,
		transportPriceForClientWithVat: 0,
	});

	const getProducts = useCallback(async () => {
		try {
			const resp = await ProductService.getList({
				status: 'all',
				categoryId,
				limit: 100,
				offset: 0,
				role: 'customer',
			});
			setProducts(resp.data);
		} catch (e) {}
	}, [categoryId]);

	useEffect(() => {
		getProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId]);

	const address = useMemo(() => {
		if (!order?.location) return '';

		const { street, houseNumber, postalCode, city, country }: any =
			order.location.address;

		const fullAddress = [
			[street, houseNumber].filter(Boolean).join(' '),
			postalCode,
			city,
			country,
		]
			.filter(Boolean)
			.join(', ');

		return fullAddress;
	}, [order?.location]);

	const { totalQuantityCounter, inputQuantity } = useContext(
		QuantityPickerContext,
	);

	useEffect(() => {
		if (totalQuantityCounter?.length) {
			const result = totalQuantityCounter?.reduce(
				(total, item) => total + item.payload * item.quantity,
				0,
			);
			setQuantity(result);
		} else {
			setQuantity(0);
		}
	}, [totalQuantityCounter]);

	useEffect(() => {
		if (inputQuantity) {
			setQuantity(inputQuantity);
		}
	}, [inputQuantity]);

	const currentProduct = useMemo(() => {
		return products?.items?.find((item) => item.id === +productId!);
	}, [products, productId]);
	const currentCategory = useMemo(() => {
		return categories?.items?.find((item) => item.id === categoryId);
	}, [categories, categoryId]);
	const currentGroup = useMemo(() => {
		return groups?.items?.find((item) => item.id === groupId);
	}, [groups, groupId]);

	const getPrices = useCallback(async () => {
		try {
			if (!currentProduct?.id) {
				return;
			}
			let quantity = 0;
			let trucksNr = 1;
			if (totalQuantityCounter?.length) {
				quantity = totalQuantityCounter?.reduce(
					(total, item) => total + item.payload * item.quantity,
					0,
				);
				trucksNr = totalQuantityCounter?.reduce(
					(total, item) => total + item.quantity,
					0,
				);
			}
			if (inputQuantity) {
				quantity = inputQuantity;
			}
			let latDelivery = 0;
			let longDelivery = 0;
			if (order?.location?.locationId) {
				const resp = await GeoService.getLocationDetails({
					locationid: order?.location?.locationId,
				});
				latDelivery = resp?.data?.Latitude;
				longDelivery = resp?.data?.Longitude;
			}
			const { data } = await OrdersService.calculatePrice({
				quantity,
				trucksNr,
				latPickup: currentProduct?.location?.coordinates?.length
					? currentProduct?.location?.coordinates[1]
					: 47.04014,
				longPickup: currentProduct?.location?.coordinates.length
					? currentProduct?.location?.coordinates[0]
					: 28.80058,
				latDelivery,
				longDelivery,
				productId: currentProduct?.id!,
			});
			setPrices(data);
		} catch (e) {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentProduct?.id,
		inputQuantity,
		order?.location?.locationId,
		order?.product?.location?.coordinates,
		totalQuantityCounter,
	]);

	useEffect(() => {
		getPrices();
	}, [getPrices]);

	const next = () => {
		const newOrder = {
			...order,
			product: currentProduct,
			category: currentCategory,
			group: currentGroup,
		};
		setOrder({
			...newOrder,
		});
		navigate(
			`/store/groups/${groupId}/categories/${categoryId}/products/${productId}/date`,
		);
	};

	return (
		<div className="w-full flex flex-col gap-4 h-[calc(100vh-60px)] gap-x-2.5 overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0">
			{/*<SearchBar
				placeholder={t(
					'clientSelectQuantity.searchPlacehoder',
					'Search Products',
				)}
			/>*/}

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center flex flex-col gap-4 pt-4 sm:mb-4 ml-[14px] sm:ml-5 xl:ml-0">
				<SypacBreadcrumbs>
					<div
						className="cursor-pointer text-cornflower-blue"
						onClick={() => navigate('/store/groups')}
					>
						<T keyName="clientSelectQuantity.store">Store</T>
					</div>
					<div className="sm:hidden">...</div>
					<div
						className="hidden sm:block cursor-pointer text-cornflower-blue"
						onClick={() =>
							navigate(`/store/groups/${currentGroup?.id}/categories`)
						}
					>
						{currentGroup?.name}
					</div>
					<div
						className="hidden sm:block cursor-pointer text-cornflower-blue"
						onClick={() =>
							navigate(
								`/store/groups/${currentGroup?.id}/categories/${categoryId}/products`,
							)
						}
					>
						{currentCategory?.name}
					</div>
					<div className="text-cornflower-blue">{currentProduct?.name}</div>
				</SypacBreadcrumbs>
				<div className="flex items-center gap-4">
					<div className="hidden sm:flex gap-4">
						<span className="scale-[1.2]">
							<MapIcon green={true} />
						</span>
						<SypacText variant="body-regular-medium">
							<p className="align-middle text-gray-80">
								<T keyName="clientSelectQuantity.deliveryAddress">
									Delivery address:
								</T>
							</p>
						</SypacText>
					</div>

					<span className="mt-[2px]">
						<Flag
							className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
							code={order?.location?.countryCode?.toLocaleUpperCase()}
							width={24}
							height={16}
						/>
					</span>
					<SypacText variant="body-regular-medium">
						<p className="align-middle text-gray-80">{address}</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<a
							href="/store"
							className="flex flex-row justify-center items-center h-[22px] w-[22px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-md"
						>
							<Close width="10" height="10" />
						</a>
					</SypacButton>
				</div>
			</div>
			<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center sm:py-3 ml-[14px] sm:ml-5 xl:ml-0">
				<ClientStepper currentStep={1} />
			</div>
			<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

			<div className="sm:w-[calc(100vw-154px)] xl:w-[1133px] xl:self-center flex flex-col sm:flex-row gap-6 sm:justify-between mb-6 sm:mb-0 mt-[10px] xl:mt-[15px] sm:ml-5 sm:mr-7.5 xl:mx-0">
				<div className="w-[calc(100vw-26px)] sm:w-fit flex-grow flex flex-col justify-between ml-[14px] sm:ml-0">
					<QuantityPicker
						counters={COUNTERS}
						availableQuantity={currentProduct?.availableQuantity!}
						unlimited={currentProduct?.unlimited!}
					/>
					<div className="hidden sm:flex gap-5 justify-center">
						<SypacButton
							variant="secondary"
							size="small"
							onClick={() => {
								navigate(-1);
							}}
							className="w-full"
						>
							<button className="flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full transition">
								<SypacIcon
									size="custom"
									width="10px"
									height="12px"
									icon-name="Alt Arrow Left"
								/>
								<SypacText variant="body-normal-medium">
									<p className="mt-[3px]">
										<T keyName="clientSelectQuantity.back">Back</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton
							className="w-full"
							variant="secondary"
							size="small"
							onClick={() => next()}
						>
							<button
								className="flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full transition"
								disabled={quantity === 0}
							>
								<SypacText variant="body-normal-medium">
									<p className="mt-[3px]">
										<T keyName="clientSelectQuantity.next">Next</T>
									</p>
								</SypacText>
								<SypacIcon
									size="custom"
									width="10px"
									height="12px"
									icon-name="Alt Arrow Right"
								/>
							</button>
						</SypacButton>
					</div>
				</div>

				<div className="sm:w-[418px] xl:w-[460px] inline-table">
					<ClientOrderSummary
						currentProduct={currentProduct}
						prices={prices}
						quantity={quantity}
						step={1}
					/>
				</div>

				<div className="w-[calc(100vw-26px)] flex flex-col sm:hidden gap-5 justify-center ml-[14px]">
					<SypacButton
						className="w-full"
						variant="secondary"
						size="small"
						onClick={() => next()}
					>
						<button
							className="flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full transition"
							disabled={quantity === 0}
						>
							<SypacText variant="body-normal-medium">
								<p className="mt-[3px]">
									<T keyName="clientSelectQuantity.next">Next</T>
								</p>
							</SypacText>
							<SypacIcon
								size="custom"
								width="10px"
								height="12px"
								icon-name="Alt Arrow Right"
							/>
						</button>
					</SypacButton>
					<SypacButton
						variant="secondary"
						size="small"
						onClick={() => {
							navigate(-1);
						}}
						className="w-full"
					>
						<button className="flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full transition">
							<SypacIcon
								size="custom"
								width="10px"
								height="12px"
								icon-name="Alt Arrow Left"
							/>
							<SypacText variant="body-normal-medium">
								<p className="mt-[3px]">
									<T keyName="clientSelectQuantity.back">Back</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</div>
	);
};

export default ClientSelectQuantity;
