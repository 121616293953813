import React, { useMemo, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	CommonNotificationsEnums,
	OrderInterface,
} from '../../../../../components/OrdersTable/interfaces/Order.interface';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { DumpTruck } from '../../../../../assets/DumpTruck';
import { SemiTrailer } from '../../../../../assets/SemiTrailer';
import { Hooklifter } from '../../../../../assets/Hooklifter';
import { Calendar } from '../../../../../assets/Calendar';
import dayjs from 'dayjs';
import Flag from 'react-world-flags';
import { DriverDto } from '../../../../../services/truck.services';
import ModalMap from '../../../../../components/ModalMap/ModalMap';
import StatusBadge from '../../../../../components/StatusBadge/StatusBadge';
import { Clock } from '../../../../../assets/Clock';
import { convertToTimeZone } from '../../../../../utils/time.util';
import TooltipCircle from '../../../../../assets/TooltipCircle';
import { Tooltip } from 'react-tooltip';
import { MapIcon } from '../../../../../assets/MapIcon';

interface TruckScheduleProps {
	selectedOrder: OrderInterface | undefined;
	allocatedTruck?: DriverDto;
}

const TruckSchedule: React.FC<TruckScheduleProps> = (props) => {
	const { t } = useTranslate();
	const { selectedOrder, allocatedTruck } = props;
	const [openMap, setOpenMap] = useState<boolean>(false);
	const [currentTruckStatus, setCurrentTruckStatus] = useState<string>('');

	const orderTruck = useMemo(
		() => selectedOrder?.trucks?.[0],
		[selectedOrder?.trucks],
	);

	const isPickUpDate = (status: string) => {
		const pickUpDateStatuses = [
			'requested',
			'accepted',
			'to_loading',
			'arrived_to_loading',
			'loading',
		];
		return pickUpDateStatuses.includes(status);
	};

	const variationPercent = (weight: number, quantity: number) => {
		const result = ((weight - quantity) / quantity) * 100 || 0;
		const percent = Number.isInteger(result)
			? result
			: parseFloat(result.toFixed(2));
		return result > 0 ? `+${percent}` : `${percent}`;
	};

	const openViewOnMap = (status: string) => {
		setCurrentTruckStatus(status);
		setOpenMap(true);
	};

	const closeViewOnMap = () => {
		setCurrentTruckStatus('');
		setOpenMap(false);
	};

	const TruckIcon = ({ type }: { type: string }) => {
		switch (type) {
			case 'aluminium':
				return <DumpTruck />;
			case 'steel':
				return <SemiTrailer />;
			case 'hooklifter':
				return <Hooklifter />;
			default:
				return null;
		}
	};

	const InfoRow = ({ label, value, children, className = '' }: any) => (
		<div className={`grid grid-cols-[250px,auto] items-start ${className}`}>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40">{label}</p>
			</SypacText>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-80">{children || value}</p>
			</SypacText>
		</div>
	);

	const referenceFormatter = (id: string) => {
		return id.substring(0, 3) + ' ' + id.substring(3);
	};

	return (
		<>
			<div className="bg-white border border-solid border-gray-10 rounded-xl">
				<SypacText variant="body-regular-medium" className="px-5 pt-4 mb-4">
					<p className="text-gray-60">
						{allocatedTruck || isPickUpDate(orderTruck?.orderStatus!) ? (
							<T keyName="deliverySchedule.pickUpSchedule">Pick-up schedule</T>
						) : (
							<T keyName="deliverySchedule.deliverySchedule">
								Delivery schedule
							</T>
						)}
					</p>
				</SypacText>

				<div className="flex flex-col gap-4 pb-4">
					{selectedOrder?.trucks?.map((orderTruck, index) => (
						<div className="flex flex-col gap-4" key={orderTruck?.id}>
							<div className="flex gap-4 px-5">
								<span className="w-[98px] h-[53px] flex items-center justify-center bg-alabaster border border-solid border-gray-10 rounded-[10px]">
									<TruckIcon type={orderTruck?.truckType} />
								</span>
								<div className="flex flex-col gap-[5px] justify-center">
									<SypacText variant="body-regular-medium">
										<p className="leading-[26px] text-gray-90">
											{orderTruck?.truckType?.charAt(0).toUpperCase()! +
												orderTruck?.truckType?.slice(1)}
										</p>
									</SypacText>
									<SypacText variant="body-regular-small">
										<p className="text-gray-40">
											<T keyName="quantityPicker.payload">Payload:</T>{' '}
											{orderTruck?.payload}{' '}
											<T keyName="quantityCounter.tons">tons</T>
										</p>
									</SypacText>
								</div>

								{(allocatedTruck || orderTruck.driver) && (
									<div className="h-[22px] ml-auto">
										<StatusBadge
											status={allocatedTruck?.status || orderTruck.orderStatus!}
											isTruckStatus={true}
											target={
												allocatedTruck ? selectedOrder?.target : undefined
											}
											deliveryIncluded={
												orderTruck.driver
													? selectedOrder?.deliveryIncluded
													: undefined
											}
										/>
									</div>
								)}
							</div>
							<div className="border-0 border-solid border-t-[1px] border-gray-10" />

							<div className="flex flex-col gap-3 px-5">
								{!allocatedTruck ? (
									<>
										<div
											className={`grid grid-cols-[250px,auto] items-start ${
												!orderTruck.driver ? 'mb-4' : ''
											}`}
										>
											<SypacText variant="body-regular-medium">
												<p className="text-gray-40">
													{isPickUpDate(orderTruck.orderStatus) ? (
														<T keyName="clientDeliveryDetails.scheduledPickUpDate">
															Scheduled pick-up date:
														</T>
													) : (
														<T keyName="clientDeliveryDetails.deliveryDate">
															Scheduled delivery date:
														</T>
													)}
												</p>
											</SypacText>
											<div className="flex flex-col gap-3">
												<div className="flex gap-3 [&_p]:text-gray-80">
													<span className="scale-[1.1]">
														<Calendar />
													</span>
													{isPickUpDate(orderTruck.orderStatus) ? (
														<SypacText variant="body-regular-medium">
															<p>
																{dayjs(orderTruck?.deliveryFrom).format(
																	'DD MMM. YYYY',
																)}
															</p>
														</SypacText>
													) : (
														<SypacText variant="body-regular-medium">
															<p>
																{dayjs(orderTruck?.deliveryTo).format(
																	'DD MMM. YYYY',
																)}
															</p>
														</SypacText>
													)}
												</div>
												<div className="flex gap-3 [&_p]:text-gray-80">
													<span className="scale-[1.1]">
														<Clock />
													</span>
													<div className="flex gap-5 items-center">
														{selectedOrder?.trucks ? (
															convertToTimeZone(orderTruck.deliveryTimeFrom) ===
																'0:00' &&
															convertToTimeZone(orderTruck.deliveryTimeTo) ===
																'23:59' ? (
																<SypacText variant="body-regular-medium">
																	<p>Full Time</p>
																</SypacText>
															) : (
																<>
																	<SypacText variant="body-regular-medium">
																		<p>
																			{convertToTimeZone(
																				orderTruck.deliveryTimeFrom,
																			)}
																		</p>
																	</SypacText>
																	<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
																	<SypacText variant="body-regular-medium">
																		<p>
																			{convertToTimeZone(
																				orderTruck.deliveryTimeTo,
																			)}
																		</p>
																	</SypacText>
																</>
															)
														) : null}
													</div>
												</div>
											</div>
										</div>

										{selectedOrder?.notifications?.items?.find(
											(obj) =>
												obj.category ===
												CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS,
										) ? (
											<div className="grid grid-cols-[250px,auto] items-start">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-40">
														<T keyName="basicDetails.deliveryDate">
															Delivery date:
														</T>
													</p>
												</SypacText>

												<div className="flex gap-3">
													<span className="scale-[1.1]">
														<Calendar />
													</span>
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">
															{dayjs(
																selectedOrder?.notifications?.items?.find(
																	(obj) =>
																		obj.category ===
																		CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS,
																)?.createdAt,
															).format('DD MMM. YYYY')}
														</p>
													</SypacText>
												</div>
											</div>
										) : (
											orderTruck?.loadedQuantity && (
												<InfoRow
													label={t(
														'truckDetails.estimatedDateOfArrival',
														'Estimated date of arrival:',
													)}
												>
													<div className="flex gap-3 [&_p]:text-gray-80">
														<span className="scale-[1.1]">
															<Calendar />
														</span>
														<SypacText variant="body-regular-medium">
															<p>
																{dayjs(
																	selectedOrder?.trucks?.[0].deliveryTo,
																).format('DD MMM. YYYY')}
															</p>
														</SypacText>
													</div>
												</InfoRow>
											)
										)}

										{orderTruck.driver && (
											<div className="grid grid-cols-[250px,auto] items-start">
												<SypacText variant="body-regular-medium">
													{isPickUpDate(orderTruck.orderStatus) ? (
														<p className="text-gray-40">
															<T keyName="basicDetails.pickUpAddress">
																Pick-up address:
															</T>
														</p>
													) : (
														<p className="text-gray-40">
															<T keyName="baseOrder.deliveryAddress">
																Delivery address:
															</T>
														</p>
													)}
												</SypacText>
												<div className="flex flex-col gap-3">
													<div className="flex items-center gap-2">
														<span className="-ml-[2px] mt-[2px]">
															<Flag
																className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
																code={
																	isPickUpDate(orderTruck.orderStatus)
																		? selectedOrder?.pickupCountryCode
																		: selectedOrder?.deliveryCountryCode
																}
																width={24}
																height={16}
															/>
														</span>
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80 w-[296px] truncate">
																{isPickUpDate(orderTruck.orderStatus)
																	? selectedOrder?.pickupAddress
																	: selectedOrder?.deliveryAddress}
															</p>
														</SypacText>
													</div>
													<div className="flex items-center gap-2">
														<span className="-ml-[2px] mr-[2px] scale-[1.2]">
															<MapIcon />
														</span>
														<SypacButton variant="secondary" size="small">
															<button
																className="w-full border-none bg-transparent p-0 group"
																onClick={() =>
																	openViewOnMap(orderTruck.orderStatus)
																}
																type="button"
															>
																<SypacText variant="body-normal-medium">
																	<p className="text-cornflower-blue transition group-hover:text-gray-80">
																		<T keyName="orderDetails.viewLocationOnMap">
																			View location on the map
																		</T>
																	</p>
																</SypacText>
															</button>
														</SypacButton>
													</div>
												</div>
											</div>
										)}

										<InfoRow
											label={t('assignOrder.referenceId', 'Reference ID:')}
										>
											<div className="flex items-center gap-3">
												<SypacText variant="body-regular-medium">
													{orderTruck.driver ? (
														<p className="text-cornflower-blue font-bold">
															{referenceFormatter(orderTruck.referenceId!)}
														</p>
													) : (
														<p className="text-gray-80">
															<T keyName="deliverySchedule.pendingAllocation">
																Pending allocation
															</T>
														</p>
													)}
												</SypacText>
												<span
													data-tooltip-id="truck-schedule"
													data-tooltip-content={t(
														'truckSchedule.referenceId',
														'Truck Reference ID: A unique code',
													)}
													className="p-0 m-0 mb-[2px] flex scale-[1.1]"
												>
													<TooltipCircle />
												</span>
											</div>
										</InfoRow>

										{orderTruck.driver && (
											<>
												<InfoRow
													label={t(
														'truckDetails.orderedQuantity',
														'Ordered quantity:',
													)}
													value={`${orderTruck?.payload} tons`}
													className={!orderTruck?.loadedQuantity ? 'mb-4' : ''}
												/>
												{orderTruck?.loadedQuantity && (
													<InfoRow
														label={t(
															'baseOrderDetails.loadedQuantity',
															'Loaded quantity:',
														)}
														className="mb-4"
													>
														<div className="flex gap-3">
															<SypacText variant="body-regular-medium">
																<p className="text-gray-80 font-bold">
																	{orderTruck?.loadedQuantity % 1 === 0
																		? orderTruck?.loadedQuantity
																		: orderTruck?.loadedQuantity.toFixed(
																				2,
																		  )}{' '}
																	<T keyName="basicDetails.tons">tons</T>
																</p>
															</SypacText>
															<span className="flex items-center bg-gray-10 rounded px-1">
																<SypacText variant="body-regular-medium">
																	<p className="text-xs leading-none">
																		{variationPercent(
																			orderTruck.loadedQuantity,
																			orderTruck.payload,
																		)}
																		%
																	</p>
																</SypacText>
															</span>
															<SypacText
																variant="body-regular-medium"
																className="self-center"
															>
																<p className="text-sm text-gray-40 leading-none">
																	<T keyName="baseOrderDetails.quantityDifference">
																		Quantity difference
																	</T>
																</p>
															</SypacText>
														</div>
													</InfoRow>
												)}
												<InfoRow
													label={t(
														'deliverySchedule.driverName',
														'Driver name:',
													)}
													value={orderTruck.driver?.driverFullName || '-'}
												/>
												<InfoRow
													label={t(
														'truckDetails.licensePlates',
														'License plates:',
													)}
													value={orderTruck.driver?.licensePlates || '-'}
												/>
												<InfoRow
													label={t('truckDetails.truckMake', 'Truck make:')}
													value={orderTruck.driver?.truckBrand || '-'}
												/>
											</>
										)}
									</>
								) : (
									<>
										<InfoRow
											label={t('deliverySchedule.driverName', 'Driver name:')}
											value={allocatedTruck?.driverFullName || '-'}
										/>
										<InfoRow
											label={t('truckDetails.licensePlates', 'License plates:')}
											value={allocatedTruck?.licensePlates || '-'}
										/>
										<InfoRow
											label={t('truckDetails.truckMake', 'Truck make:')}
											value={allocatedTruck?.truckBrand || '-'}
										/>
									</>
								)}
							</div>
						</div>
					))}
				</div>
			</div>

			{openMap ? (
				<ModalMap
					isOpen={openMap}
					onClose={() => closeViewOnMap()}
					markers={[
						{
							latitude: isPickUpDate(currentTruckStatus)
								? selectedOrder?.pickupLocation?.coordinates?.at(1)!
								: selectedOrder?.deliveryLocation?.coordinates?.at(1)!,
							longitude: isPickUpDate(currentTruckStatus)
								? selectedOrder?.pickupLocation?.coordinates?.at(0)!
								: selectedOrder?.deliveryLocation?.coordinates?.at(0)!,
							variant: isPickUpDate(currentTruckStatus) ? 'pickup' : 'delivery',
							...(isPickUpDate(currentTruckStatus)
								? {
										pickup: {
											pickupAddress: selectedOrder?.pickupAddress!,
											pickupCountryCode: selectedOrder?.pickupCountryCode!,
										},
								  }
								: {
										delivery: {
											deliveryAddress: selectedOrder?.deliveryAddress!,
											deliveryCountryCode: selectedOrder?.deliveryCountryCode!,
										},
								  }),
						},
					]}
				/>
			) : null}
			<Tooltip
				place="top"
				id="truck-schedule"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
					whiteSpace: 'normal',
					maxWidth: '248px',
				}}
			/>
		</>
	);
};

export default TruckSchedule;
