import React, { useState } from 'react';
import {
	SypacBox,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import classNames from 'classnames';
import { ChevronDownIcon } from '../../assets/ChevronDownIcon';
import { DumpTruck } from '../../assets/DumpTruck';
import Flag from 'react-world-flags';
import { DriverDto, TruckResponse } from '../../services/truck.services';
import { SemiTrailer } from '../../assets/SemiTrailer';

interface CompanyFleetProps {
	data: TruckResponse | undefined;
}

const CompanyFleet = (props: CompanyFleetProps) => {
	const { data } = props;
	const [showMore, setShowMore] = useState<{ [key: string]: boolean }>({});

	const toggleShowMore = (id: number) => {
		setShowMore((prev) => ({ ...prev, [id]: !prev[id] }));
	};

	const fieldDefinitions = {
		initialFields: [
			{ label: 'Login code', key: 'code' },
			{ label: 'Truck ID number', key: 'id' },
			{ label: 'Truck plates', key: 'licensePlates' },
		],
		additionalFieldsOne: [
			{ label: 'Truck make', key: 'truckBrand' },
			{ label: 'Truck model', key: 'truckModel' },
			{ label: 'Truck color', key: 'truckColor' },
			{ label: 'Year of production', key: 'truckYear' },
			{ label: 'Emission type', key: 'emissionType' },
		],
		additionalFieldsTwo: [
			{ label: 'Driver first name', key: 'driverFullName' },
			{ label: 'Driver last name', key: 'driverFullName' },
			{ label: 'Driver phone number', key: 'driverPhone' },
		],
	};

	const getNameParts = (fullName: string) => {
		const parts = fullName.trim().split(' ');
		const lastName = parts.pop();
		const firstName = parts.join(' ');
		return { firstName, lastName };
	};

	const InfoField = ({ label, value, countryCode }: any) => {
		let displayValue = value;
		if (label === 'Driver first name' && typeof value === 'string') {
			displayValue = getNameParts(value).firstName;
		} else if (label === 'Driver last name' && typeof value === 'string') {
			displayValue = getNameParts(value).lastName;
		} else if (label === 'Truck color' && typeof value === 'string') {
			displayValue = value.charAt(0).toUpperCase() + value.slice(1);
		}

		return (
			<div className="flex w-[380px] justify-between whitespace-nowrap">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">{label}:</p>
				</SypacText>
				<div className="w-[150px]">
					{label === 'Driver phone number' ? (
						<div className="flex col-span-7 gap-2.5 items-center">
							<span className="rounded-[3px] overflow-hidden">
								<Flag code={countryCode} width={24} height={16} />
							</span>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 -tracking-[0.01em]">
									{value ? `+${String(value)}` : '-'}
								</p>
							</SypacText>
						</div>
					) : (
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{displayValue ? String(displayValue) : '-'}
							</p>
						</SypacText>
					)}
				</div>
			</div>
		);
	};

	const renderFields = (fields: any, truck: any) =>
		fields.map((field: any) => (
			<InfoField
				key={field.label}
				label={field.label}
				value={truck[field.key]}
				countryCode={truck.company.countryCode}
			/>
		));

	return (
		<div className="flex flex-col company-information-border px-10 py-7 gap-6">
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40 -tracking-[0.01em]">Fleet size</p>
			</SypacText>

			{data?.items?.map((truck: DriverDto) => (
				<SypacBox
					key={truck.id}
					hover={false}
					box-style="solid"
					box-color="primary"
					className="bg-alabaster transition hover:border-gray-80"
				>
					<div className="flex justify-between px-5 py-4 border-0 border-b border-solid border-gray-10">
						<div className="flex gap-6 items-center">
							<div className="flex items-center justify-center w-[100px] h-[55px] border border-solid border-gray-10 rounded-[10px] bg-white">
								{truck.type === 'Dump Truck' ? <DumpTruck /> : <SemiTrailer />}
							</div>
							<div className="flex flex-col gap-2">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="companyStats.statistics">{truck.type}</T>
									</p>
								</SypacText>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40">{`Payload: ${truck.truckPayload} tons`}</p>
								</SypacText>
							</div>
						</div>
						{/*<SypacBadge color="gray" outlined={false}>
							<div className="flex justify-between items-center text-sm font-medium whitespace-nowrap min-w-[110px] max-h-[22px]">
								<span className="p-0 m-0 flex scale-[1.2]">
									<StatusClock />
								</span>
								<p className="m-0 flex-1 text-center">In Progress</p>
							</div>
						</SypacBadge>*/}
					</div>

					<div className="flex flex-col gap-4 p-5 border-0 border-b-0 border-solid border-gray-10">
						{renderFields(fieldDefinitions.initialFields, truck)}
					</div>

					<div
						className={`transition-all ease-in-out duration-500 ${
							showMore[truck.id]
								? 'max-h-screen opacity-100'
								: 'max-h-0 opacity-0 overflow-hidden'
						}`}
					>
						<div className="flex flex-col gap-4 p-5 border-0 border-b-0 border-solid border-gray-10">
							{renderFields(fieldDefinitions.additionalFieldsOne, truck)}
						</div>
						<div className="flex flex-col gap-4 p-5 border-0 border-b-0 border-solid border-gray-10">
							{renderFields(fieldDefinitions.additionalFieldsTwo, truck)}
						</div>
					</div>

					<div className="flex justify-center px-5 py-1 border-0 border-t border-solid border-gray-10">
						<SypacButton
							variant="secondary"
							size="small"
							onClick={() => toggleShowMore(truck.id)}
						>
							<button
								className="px-5 py-2 border-0 bg-transparent"
								type="button"
							>
								<SypacText variant="body-regular-medium" className="mr-3">
									<p className="text-primary-violet">
										{showMore[truck.id] ? (
											<T keyName="companyStats.showLess">Show less</T>
										) : (
											<T keyName="companyStats.showMore">Show more</T>
										)}
									</p>
								</SypacText>
								<span
									className={classNames(
										'flex my-auto transform mr-1 transition-all duration-300 ease-in-out',
										{
											'-rotate-180': showMore[truck.id],
										},
									)}
								>
									<ChevronDownIcon />
								</span>
							</button>
						</SypacButton>
					</div>
				</SypacBox>
			))}
		</div>
	);
};

export default CompanyFleet;
